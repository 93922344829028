var _ = require('underscore')
var http = require('./http')
var prototype = require('prote')

module.exports = prototype({
  constructor: function (properties) {
    this.lexemes = []
    this.acceptedLexemes = {}
    if (properties) {
      _.extend(this, properties)
    }
  },

  update: function (doc) {
    _.extend(this, doc)
    return http.post(this.href, this)
  }
})
