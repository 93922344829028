const hyperdom = require('hyperdom')

module.exports = class CodedOutputView {
  constructor ({history}) {
    this.history = history
  }

  render () {
    return <div class="coded-output">
      <p>
        {
          this.history.currentLexemes().map(lexeme => {
            return lexeme.response.id + (lexeme.response.changedStyles.style1 ? JSON.stringify(lexeme.response.styles.style1) : '')
          }).join(', ')
        }
      </p>
    </div>
  }
}
