var hyperdom = require('hyperdom')
var h = hyperdom.html
var enoteComponent = require('./enoteComponent')
var prototype = require('prote')
var authComponents = require('./authComponents')
var layoutComponent = require('./layoutComponent')
var documentApi = require('./documentApi')()
var routes = require('./routes')
var buildGraph = require('./buildGraph')
var lexemeApi = require('./lexemeApi')
var documentsComponent = require('./documentsComponent')
var adminComponent = require('./adminComponent')
var authoringComponent = require('./routes/authoring/blocks/block')
var contentPage = require('./contentPage')

var rootComponent = prototype({
  constructor: function (pageData) {
    this.user = pageData.user
    this.flash = pageData.flash
    this.blogUrl = pageData.blogUrl
    this.releaseTime = pageData.releaseTime

    if (pageData.user) {
      this.documentApi = documentApi
      this.admin = adminComponent()

      this.documentsComponent = documentsComponent({
        documentApi: documentApi,
        root: {openDocument: this.openDocument.bind(this)},
        user: this.user,
        currentDocument: this.currentDocument.bind(this)
      })
    }
  },

  openDocumentById: async function (docId) {
    var self = this

    if (docId != this.documentId) {
      try {
        const doc = await this.documentApi.document(docId, {showErrors: false})
        this.openDocument(doc)
      } catch (error) {
        if (error.status == 404) {
          this.documentNotFound = true
        } else {
          throw error
        }
      }
    } else {
      self.openDocument(self.document)
    }
  },

  query: function () {
    return this.enote && this.enote.history.query
  },

  lexemeApi: function () {
    return this._lexemeApi || (
      this._lexemeApi = lexemeApi()
    )
  },

  queryGraph: function () {
    return this._queryGraph || (
      this._queryGraph = buildGraph({
        startingPredicants: ['H&P', 'any-user', 'generic-physician', 'user:' + this.user.id],
        lexemeApi: this.lexemeApi(),
        cache: false
      })
    )
  },

  openDocument: function (doc) {
    this.documentNotFound = false
    this.document = doc
    this.setCurrentDocument(doc)
    this.documentId = doc.id
    this.enote = enoteComponent({
      user: this.user,
      document: doc,
      queryGraph: this.queryGraph(),
      lexemeApi: this.lexemeApi()
    })
  },

  currentDocument: function () {
    var self = this

    if (!this._loadedCurrentDocument && this.user) {
      this._loadedCurrentDocument = true

      self.documentApi.currentDocument().then(function (doc) {
        if (!self._currentDocument) {
          self.setCurrentDocument(doc)
          self.refresh()
        }
      })
    }

    return this._currentDocument
  },

  setCurrentDocument: function (doc) {
    this._currentDocument = doc
  },

  authoringComponent: function () {
    if (!this._authoringComponent) {
      this._authoringComponent = authoringComponent()
    }

    return this._authoringComponent
  },

  renderLayout (vdom) {
    return layoutComponent(this, vdom)
  },

  routes () {
    return [
      routes.login({
        render: (location) => authComponents.login(location.params)
      }),
      routes.signup({
        render: (location) => authComponents.signup(location.params)
      }),
      routes.resetPassword({
        render: (location) => authComponents.resetPassword(location.params.token)
      }),
      routes.forgotPassword({
        render: (location) => authComponents.forgotPassword(location.params)
      }),
      (location) => {
        if (location.isNewUrl && !this.user) {
          return location.redirect(routes.login.url())
        } else {
          return location.renderRoutes([
            routes.root({
              onload: () => this.documentsComponent.loadDocuments(),
              render: () => {
                return this.documentsComponent
              }
            }),
            routes.enote.base({
              onload: async (location) => {
                await this.openDocumentById(location.params.documentId)
              },

              render: (location) => {
                if (this.documentNotFound) {
                  return h('h1.center', 'Very sorry! We couldn\'t find this document.')
                } else if (!this.enote) {
                  return h('div', 'loading')
                } else {
                  return location.renderRoutes([
                    routes.enote({
                      render: () => {
                        return this.enote.render()
                      }
                    }),
                    routes.printEnote({
                      render: () => {
                        return this.enote.renderPrint()
                      }
                    }),
                  ])
                }
              }
            }),
            routes.authoring.base((location) => {
              return location.renderRoutes(this.authoringComponent())
            }),
            routes.admin.base((location) => {
              if (this.user.admin) {
                return location.renderRoutes(this.admin)
              } else {
                return h('h1', 'Sorry, you need to be an administrator to see this page.')
              }
            }),
            routes.contentPage({
              onload: location => {
                this.contentPage = contentPage(location.params.page)
              },
              render: () => this.contentPage
            }),
          ])
        }
      },
    ]
  }
})

module.exports = rootComponent
