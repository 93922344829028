var _ = require('underscore')
var queryComponent = require('./queries/queryComponent')
var $ = require('../../../jquery')
var blockName = require('./blockName')
var queriesInHierarchyByLevel = require('./queriesInHierarchyByLevel')
const hyperdom = require('hyperdom')
var h = hyperdom.html
var http = require('../http')
var routes = require('../../../routes')
var clone = require('./queries/clone')
var predicantsComponent = require('./predicantsComponent')
var predicants = require('./predicants')
var dirtyBinding = require('./dirtyBinding')
var semanticUi = require('../../../semanticUi')

function BlockComponent () {
  this.blocks = []

  this.predicants = predicants()

  this.dirtyBinding = dirtyBinding(this)

  this.predicantsComponent = predicantsComponent({
    predicants: this.predicants
  })
}

BlockComponent.prototype.onload = async function () {
  await (this.loadingPromise = Promise.all([
    this.loadBlocks(),
    this.loadClipboard(),
    this.predicantsComponent.loadPredicants()
  ]))
  this.loaded = true
}

BlockComponent.prototype.routes = function () {
  return [
    routes.authoring({
      render: () => this.renderMenu()
    }),

    routes.authoringCreateBlock(
      {
        onload: async (location) => {
          this.askToScrollBlockQueryMenu()
          if (!this.loaded) {
            await this.loadingPromise
          }
          this.loadBlock(location.params.blockId, true)
        },

        render: (location) => {
          return [
            this.renderMenu(),
            this.renderBlockEditor(location.params.blockId)
          ]
        }
      },
    ),

    routes.authoringBlock(
      {
        onload: async (location) => {
          this.askToScrollBlockQueryMenu()
          if (!this.loaded) {
            await this.loadingPromise
          }
          this.loadBlock(location.params.blockId, false)
        },

        render: (location) => {
          return [
            this.renderMenu(),
            this.renderBlockEditor(location.params.blockId)
          ]
        }
      },
    ),

    routes.authoringCreateQuery(
      {
        onload: async (location) => {
          this.askToScrollBlockQueryMenu()
          if (!this.loaded) {
            await this.loadingPromise
          }
          this.loadBlock(location.params.blockId, false)
          this.loadCreateQuery(location.params.blockId, undefined, true)
        },

        render: () => {
          return [
            this.renderMenu(),
            this.renderQueryEditor()
          ]
        }
      }
    ),

    routes.authoringQuery(
      {
        onload: async (location) => {
          this.askToScrollBlockQueryMenu()
          if (!this.loaded) {
            await this.loadingPromise
          }
          this.loadBlock(location.params.blockId, false)
          this.loadQuery(location.params.blockId, location.params.queryId, false)
        },

        render: () => {
          return [
            this.renderMenu(),
            this.renderQueryEditor()
          ]
        }
      },
    ),

    routes.authoringCreatePredicant({
      onload: () => {
        return this.predicantsComponent.createPredicant()
      },

      render: () => {
        return [
          this.renderMenu(),
          this.renderPredicantEditor(this.selectedPredicant)
        ]
      }
    }),

    routes.authoringPredicant({
      onload: (location) => {
        return this.predicantsComponent.loadPredicant(location.params.predicantId)
      },

      render: () => {
        return [
          this.renderMenu(),
          this.renderPredicantEditor()
        ]
      }
    }),
  ]
}

BlockComponent.prototype.renderLayout = function (vdom) {
  return h('.authoring-index.edit-lexicon', vdom)
}

BlockComponent.prototype.loadBlock = function (blockId, creatingBlock) {
  this.blockId = blockId
  if (creatingBlock) {
    this.selectedBlock = this.createBlock({})
  } else {
    this.selectedBlock = this.block(blockId)
  }

  if (this.selectedBlock) {
    this.selectedBlock.startEditing()
  }
}

BlockComponent.prototype.loadCreateQuery = function (blockId) {
  return this.loadQuery(blockId, undefined, {create: true})
}

BlockComponent.prototype.loadQuery = function (blockId, queryId, {create} = {}) {
  this.queryId = queryId
  if (create) {
    this.selectedQuery = queryComponent.create()
  } else {
    this.selectedQuery = this.query(blockId, queryId)
  }

  if (this.selectedQuery) {
    this.queryComponent = queryComponent({
      query: clone(this.selectedQuery),
      originalQuery: this.selectedQuery,
      blockId,
      predicants: this.predicants,
      props: {
        removeQuery: this.removeQuery.bind(this),
        updateQuery: this.updateQuery.bind(this),
        createQuery: this.createQuery.bind(this),
        insertQueryBefore: this.insertQueryBefore.bind(this),
        insertQueryAfter: this.insertQueryAfter.bind(this),
        pasteQueryFromClipboard: this.pasteQueryFromClipboard.bind(this),
        addToClipboard: this.addToClipboard.bind(this)
      },
      blocks: this
    })
  } else {
    delete this.queryComponent
  }
}

BlockComponent.prototype.repositionQueriesList = function (element) {
  function pxNumber (x) {
    var m
    m = /(.*)px$/.exec(x)
    if (m) {
      return Number(m[1])
    } else {
      return 0
    }
  }

  if (this.blocks) {
    var buttons = $(element).find('.blocks-queries > .buttons')
    var marginBottom = buttons.css('margin-bottom')
    var top = Math.max(0, pxNumber(marginBottom) + buttons.offset().top + buttons.height() - Math.max(0, window.scrollY))
    var ol = $(element).find('.blocks-queries > .menu')
    ol.css('top', top + 'px')
  }
}

BlockComponent.prototype.query = function (blockId, queryId) {
  var block = this.block(blockId)
  if (block && block.queries) {
    return block.queries.filter(function (q) {
      return String(q.id) === String(queryId)
    })[0]
  }
}

BlockComponent.prototype.resizeQueriesDiv = function (element) {
  var queriesDiv = $(element)
  var queriesOl = $(element).find('.blocks-queries > .menu')
  var width = queriesOl.outerWidth()
  queriesDiv.css('min-width', width + 'px')
}

BlockComponent.prototype.createBlock = function (b) {
  return {
    block: b,

    startEditing: function () {
      this.editedBlock = clone(this.block)
    },

    cancelEdits: function () {
      this.editedBlock = clone(this.block)
    },

    commitEdits: function () {
      this.block = this.editedBlock
    },

    updateQueries: function () {
      var self = this

      return http.get('/api/blocks/' + b.id + '/queries').then(function (queries) {
        self.queries = queries
        self.queriesHierarchy = queriesInHierarchyByLevel(queries)
      })
    }
  }
}

BlockComponent.prototype.loadBlocks = function () {
  var self = this
  self.blocksLoaded = false

  function getBlocks () {
    return http.get('/api/blocks').then(function (blocks) {
      self.blocksById = _.indexBy(blocks, 'id')

      return blocks.map(function (b) {
        return self.createBlock(b)
      })
    }).then(function (blocks) {
      self.blocks = blocks
      return blocks
    })
  }

  this.blocksPromise = getBlocks()

  return this.blocksPromise.then(function (latestBlocks) {
    return Promise.all(latestBlocks.map(function (block) {
      return block.updateQueries()
    })).then(function () {
      self.blocksLoaded = true
      return latestBlocks
    })
  })
}

BlockComponent.prototype.loadClipboard = function () {
  var self = this
  return http.get('/api/user/queries').then(function (clipboard) {
    self.clipboard = clipboard
  })
}

BlockComponent.prototype.addToClipboard = function (query) {
  var self = this
  return http.post('/api/user/queries', query).then(function () {
    return self.loadClipboard()
  })
}

BlockComponent.prototype.removeFromClipboard = function (query) {
  var self = this
  return http.delete(query.href).then(function () {
    return self.loadClipboard()
  })
}

BlockComponent.prototype.isNewBlock = function () {
  return this.selectedBlock && this.selectedBlock.editedBlock && !this.selectedBlock.editedBlock.id
}

BlockComponent.prototype.isNewQuery = function () {
  return this.selectedQuery && !this.selectedQuery.id
}

BlockComponent.prototype.block = function (blockId) {
  var self = this
  return self.blocks.filter(function (b) {
    return String(b.block.id) === String(blockId)
  })[0]
}

BlockComponent.prototype.addQuery = function () {
  routes.authoringCreateQuery({blockId: this.blockId}).push()
}

BlockComponent.prototype.dirty = function (value) {
  this._dirty = true
  return value
}

BlockComponent.prototype.clean = function (value) {
  this._dirty = false
  return value
}

BlockComponent.prototype.save = function () {
  var self = this
  return http.post('/api/blocks/' + self.blockId, self.selectedBlock.editedBlock).then(function () {
    self.selectedBlock.commitEdits()
    self.blocksComponent.refresh()
    self.clean()
  })
}

BlockComponent.prototype.create = function () {
  var self = this
  return http.post('/api/blocks', self.selectedBlock.editedBlock).then(function (body) {
    self.clean()
    self.selectedBlock.commitEdits()
    var id = body.id
    return self.loadBlocks().then(function () {
      routes.authoringBlock.replace({blockId: id})
    })
  })
}

BlockComponent.prototype.delete = function () {
  var self = this
  self.selectedBlock.editedBlock.deleted = true
  return http.post('/api/blocks/' + self.blockId, self.selectedBlock.editedBlock).then(function () {
    return self.loadBlocks().then(function () {
      routes.authoring().replace()
    })
  })
}

BlockComponent.prototype.pasteQueryFromClipboard = function (query) {
  if (this.selectedQuery && this.queryComponent) {
    this.queryComponent.pasteQueryFromClipboard(query)
  }
}

BlockComponent.prototype.cancel = function () {
  this.selectedBlock.cancelEdits()
  routes.authoring.push()
}

BlockComponent.prototype.createQuery = function (q) {
  var self = this
  return http.post('/api/blocks/' + self.blockId + '/queries', q).then(function (body) {
    routes.authoringQuery.replace({blockId: self.blockId, queryId: body.id})
    return self.selectedBlock.updateQueries()
  })
}

BlockComponent.prototype.updateQuery = function (q) {
  var self = this
  return http.post('/api/blocks/' + self.blockId + '/queries/' + q.id, q).then(function () {
    return self.selectedBlock.updateQueries()
  })
}

BlockComponent.prototype.insertQueryBefore = function (q) {
  var self = this
  q.before = q.id
  q.id = void 0
  return http.post('/api/blocks/' + self.blockId + '/queries', q).then(function (body) {
    self.dontScrollToBlockQuery()
    routes.authoringQuery.push({blockId: self.blockId, queryId: body.id})
    return self.selectedBlock.updateQueries()
  })
}

BlockComponent.prototype.insertQueryAfter = function (q) {
  var self = this
  q.after = q.id
  q.id = void 0
  return http.post('/api/blocks/' + self.blockId + '/queries', q).then(function (body) {
    self.dontScrollToBlockQuery()
    routes.authoringQuery.push({blockId: self.blockId, queryId: body.id})
    return self.selectedBlock.updateQueries()
  })
}

BlockComponent.prototype.removeQuery = function (q) {
  var self = this
  q.deleted = true
  return http.post('/api/blocks/' + self.blockId + '/queries/' + q.id, q).then(function () {
    self.dontScrollToBlockQuery()
    routes.authoringBlock.replace({blockId: self.blockId})
    return self.selectedBlock.updateQueries()
  })
}

BlockComponent.prototype.addBlock = function () {
  routes.authoringCreateBlock.push()
}

BlockComponent.prototype.renderPredicantsMenu = function () {
  return this.predicantsComponent.renderMenu()
}

BlockComponent.prototype.renderPredicantEditor = function () {
  return this.predicantsComponent.renderEditor()
}

BlockComponent.prototype.renderClipboard = function () {
  var self = this

  return h('div.clipboard',
    h('.ui.menu.vertical.secondary',
      self.clipboard
        ? self.clipboard.length
          ? self.clipboard.map(function (q) {
            function pasteFromClipboard (ev) {
              self.pasteQueryFromClipboard(q)
              ev.preventDefault()
            }

            function removeFromClipboard (ev) {
              ev.stopPropagation()
              return self.removeFromClipboard(q)
            }

            return h('a.item', {onclick: pasteFromClipboard},
              q.name,
              h('.ui.label.remove.red', {onclick: removeFromClipboard}, 'remove')
            )
          })
          : h('.item', 'no queries in clipboard')
        : undefined
    )
  )
}

BlockComponent.prototype.textValue = function (value) {
  return value || ''
}

BlockComponent.prototype.dontScrollToBlockQuery = function () {
  this.ignoreScrollToBlockQuery = true
}

BlockComponent.prototype.askToScrollBlockQueryMenu = function () {
  if (!this.ignoreScrollToBlockQuery) {
    this.scrollToBlockQuery = true
  } else {
    this.ignoreScrollToBlockQuery = false
  }
}

BlockComponent.prototype.renderMenu = function () {
  var self = this

  return h('.menu', semanticUi.tabs('.ui.tabular.menu.top.attached', {
    binding: [this, 'tab'],
    tabs: [
      {
        key: 'blocks',
        tab: h('a.item', 'Blocks'),
        content: function () { return h('.ui.bottom.attached.tab.segment', self.renderBlocksQueries()) }
      },
      {
        key: 'clipboard',
        tab: h('a.item', 'Clipboard (' + (self.clipboard? self.clipboard.length: 0) + ')'),
        content: function () { return h('.ui.bottom.attached.tab.segment', self.renderClipboard()) }
      },
      {
        key: 'predicants',
        tab: h('a.item', 'Predicants'),
        content: function () { return h('.ui.bottom.attached.tab.segment', self.renderPredicantsMenu()) }
      }
    ]
  }))
}

BlockComponent.prototype.renderBlocksQueries = function () {
  var self = this

  function renderQueries (block, queries) {
    return h('.menu', queries.map(function (tree) {
      function show (ev) {
        tree.hideQueries = false
        ev.stopPropagation()
        self.blocksComponent.refresh()
      }

      function hide (ev) {
        tree.hideQueries = true
        ev.stopPropagation()
        self.blocksComponent.refresh()
      }

      var toggle =
        tree.queries
          ? tree.hideQueries
            ? h('i.icon.chevron.right', {onclick: show})
            : h('i.icon.chevron.down', {onclick: hide})
          : h('i.icon', {onclick: hide})

      function selectQuery (ev) {
        self.dontScrollToBlockQuery()
        routes.authoringQuery.push({blockId: block.id, queryId: tree.query.id})
        ev.stopPropagation()
        ev.preventDefault()
      }

      return h('.item', {class: {'no-query': !tree.query, active: tree.query && self.queryId === tree.query.id}},
        tree.query
          ? h('.header',
            toggle,
            h('a', {href: routes.authoringQuery.href({blockId: block.id, queryId: tree.query.id}), onclick: selectQuery}, tree.query.name)
          )
          : undefined,
        (!tree.hideQueries && tree.queries)
          ? renderQueries(block, tree.queries)
          : undefined
      )
    }))
  }

  return h('div.left-panel', {key: 'edit-block-query'},
    self.blocks
      ? hyperdom.viewComponent({
        cacheKey: self.blockId + ':' + self.queryId,

        onload: function () {
          self.blocksComponent = this
        },

        onupdate: function (element) {
          function relativePosition (element, ancestor) {
            var elementOffset = $(element).offset()
            var ancestorOffset = $(ancestor).offset()

            return {
              top: elementOffset.top - ancestorOffset.top,
              left: elementOffset.left - ancestorOffset.left
            }
          }

          if (self.scrollToBlockQuery && self.blocksLoaded) {
            self.scrollToBlockQuery = false
            var items = element.querySelectorAll('.item.active')
            var item = items[items.length - 1]
            var menu = element.querySelector('.ui.menu.results')
            if (item && menu) {
              menu.scrollTop = relativePosition(item, menu).top - 60
            }
          }
        },

        render: function () {
          return h('.blocks-queries',
            h('div.buttons',
              h('button.ui.button', {onclick: self.addBlock.bind(self)}, 'Add Block')
            ),
            h('.ui.vertical.menu.secondary.results.block-query-menu', self.blocks.map(function (blockViewModel) {
              var block = blockViewModel.block

              function selectBlock (ev) {
                ev.preventDefault()
                ev.stopPropagation()
                self.dontScrollToBlockQuery()
                routes.authoringBlock.push({blockId: block.id})
              }

              function show (ev) {
                blockViewModel.showQueries = true
                ev.stopPropagation()
                self.blocksComponent.refresh()
              }

              function hide (ev) {
                blockViewModel.showQueries = false
                ev.stopPropagation()
                self.blocksComponent.refresh()
              }

              var toggle =
                  (blockViewModel.queries && blockViewModel.queries.length > 0)
                    ? blockViewModel.showQueries
                      ? h('i.icon.chevron.down', {onclick: hide})
                      : h('i.icon.chevron.right', {onclick: show})
                    : h('i.icon', {onclick: hide})

              var blockRoute = routes.authoringBlock.href({blockId: block.id})

              return h('.item', {class: {active: self.blockId === block.id}},
                h('.header',
                  toggle,
                  h('a', {href: blockRoute, onclick: selectBlock}, blockName(block))
                ),
                ((blockViewModel.showQueries || self.blockId === block.id) && blockViewModel.queriesHierarchy)
                  ? renderQueries(block, blockViewModel.queriesHierarchy)
                  : undefined
              )
            }))
          )
        }
      })
      : undefined
  )
}

BlockComponent.prototype.renderQueryEditor = function () {
  if (this.queryComponent) {
    return this.queryComponent.render()
  } else {
    return h('h1', 'loading')
  }
}

BlockComponent.prototype.renderBlockEditor = function () {
  var self = this

  function addQuery () {
    routes.authoringCreateQuery.push({blockId: self.blockId})
  }

  if (self.selectedBlock) {
    return h('div.edit-block.ui.segment',
      h('h2', 'Block'),
      h('div.buttons',
        !self.selectedBlock.editedBlock.id
          ? h('button.create', {onclick: self.create.bind(self)}, 'Create')
          : self._dirty
            ? h('button.save', {onclick: self.save.bind(self)}, 'Save')
            : undefined,
        self.selectedBlock.editedBlock.id
          ? h('button.delete', {onclick: self.delete.bind(self)}, 'Delete')
          : undefined,
        (self._dirty || self.isNewBlock())
          ? h('button.cancel', {onclick: self.cancel.bind(self)}, 'Cancel')
          : h('button.cancel', {onclick: self.cancel.bind(self)}, 'Close')
      ),
      h('ul',
        h('li',
          h('label', {for: 'block_name'}, 'Name'),
          h('input', {
            id: 'block_name',
            type: 'text',
            binding: self.dirtyBinding(self.selectedBlock.editedBlock, 'name')
          })
        )
      ),
      (self.blockId && self.selectedBlock.queries && self.selectedBlock.queries.length === 0)
        ? h('ul',
          h('li',
            h('button', {onclick: addQuery}, 'Add Query')
          )
        )
        : undefined
    )
  }
}

module.exports = function () {
  return new BlockComponent()
}
