var router = require('hyperdom/router')

if (typeof window === 'object') {
  if (!history.pushState) {
    if (location.pathname != '/') {
      var path = (location.pathname + location.search).replace(/^\//, '')
      location.href = '/#' + path
    }
    module.exports = router.router({history: router.hash()})
  } else {
    module.exports = router
  }
} else {
  module.exports = router
}
