const hyperdom = require('hyperdom')
var h = hyperdom.html
var http = require('../http')
var clone = require('./queries/clone')
var routes = require('../../../routes')

function PredicantsComponent ({onclose, predicants} = {}) {
  this.onclose = onclose
  this.predicants = predicants
  this.filteredPredicants = []
  this.usagesForSelectedPredicant = undefined
}

PredicantsComponent.prototype.createPredicant = function () {
  this.selectedPredicant = {predicant: {}}
}

PredicantsComponent.prototype.loadPredicants = async function () {
  await this.predicants.load()
  this.searchPredicants('')
}

PredicantsComponent.prototype.loadPredicant = async function (predicantId) {
  await this.loadPredicants()
  var predicant = this.predicants.predicantsById[predicantId]

  if (predicant) {
    this.selectedPredicant = {
      originalPredicant: predicant,
      predicant: clone(predicant)
    }

    this.usagesForSelectedPredicant = await http.get('/api/predicants/' + predicant.id + '/usages')
  } else {
    this.selectedPredicant = undefined
    this.usagesForSelectedPredicant = undefined
  }
}

PredicantsComponent.prototype.searchPredicants = function (query) {
  if (query) {
    var lowerCaseQuery = query.toLowerCase()
    this.filteredPredicants = this.predicants.predicants.filter(function (predicant) {
      return predicant.name.toLowerCase().indexOf(lowerCaseQuery) >= 0
    })
  } else {
    return this.filteredPredicants = this.predicants.predicants
  }
}

PredicantsComponent.prototype.refresh = function () {
}

PredicantsComponent.prototype.renderEditor = function () {
  var self = this

  this.refresh = h.refresh

  if (this.selectedPredicant) {
    return self.renderPredicantEditor(self.selectedPredicant)
  }
}

PredicantsComponent.prototype.renderMenu = function () {
  this.refresh = h.refresh

  var self = this

  return h('.predicant-search',
    h('.ui.button',
      {
        onclick: function () {
          routes.authoringPredicant.push({predicantId: 'create'})
        }
      },
      'Create'
    ),
    h('br'),
    h('.ui.icon.input',
      h('input.search', {type: 'text', placeholder: 'search predicants', binding: [this, 'query', query => this.searchPredicants(query)]}),
      h('i.search.icon')
    ),
    h('.ui.vertical.menu.secondary.results',
      this.filteredPredicants.map(function (predicant) {
        var href = routes.authoringPredicant.href({predicantId: predicant.id})
        return h('a.item.teal',
          {
            href,
            class: {active: self.selectedPredicant && self.selectedPredicant.originalPredicant && predicant.id == self.selectedPredicant.originalPredicant.id},
            onclick: function (ev) {
              routes.authoringPredicant.push({predicantId: predicant.id})
              ev.preventDefault()
            }
          },
          h('h5', predicant.name)
        )
      })
    )
  )
}

PredicantsComponent.prototype.renderPredicantEditor = function (selectedPredicant) {
  var self = this

  function buttons () {
    return h('.buttons',
      selectedPredicant.predicant.id
        ? h('button.save.ui.button.blue', {
          onclick: function () {
            return http.put(selectedPredicant.predicant.href, selectedPredicant.predicant).then(function () {
              selectedPredicant.originalPredicant.name = selectedPredicant.predicant.name
              delete self.selectedPredicant
              routes.authoring.push()
            })
          }
        }, 'Save')
        : h('button.create.ui.button.green', {
          onclick: function () {
            return http.post('/api/predicants', selectedPredicant.predicant).then(function (predicant) {
              self.predicants.addPredicant(predicant)
              delete self.selectedPredicant
              routes.authoring.push()
            })
          }
        }, 'Create'),
      h('button.close.ui.button', {
        onclick: function () {
          delete self.selectedPredicant
          routes.authoring.push()
        }
      }, 'Close')
    )
  }

  return h('.selected-predicant.ui.segment.form',
    h('h1', 'Predicant'),
    buttons(),
    h('.ui.field',
      h('label', 'Name'),
      h('.ui.input',
        h('input.name', {type: 'text', binding: h.binding([selectedPredicant.predicant, 'name'], {refresh: false})})
      )
    ),
    selectedPredicant.predicant.id
      ? h('.predicant-usages',
        h('.predicant-usages-queries',
          h('h3', 'Dependent Queries'),
          h('.ui.vertical.menu.results',
            this.usagesForSelectedPredicant && this.usagesForSelectedPredicant.queries.length
              ? this.usagesForSelectedPredicant.queries.map(function (query) {
                return h('.item.teal', h('a', {href: routes.authoringQuery.href({blockId: query.block, queryId: query.id})}, query.name))
              })
              : h('.item.teal', 'none')
          )
        ),
        h('.predicant-usages-responses',
          h('h3', 'Issuing Responses'),
          h('.ui.vertical.menu.results',
            this.usagesForSelectedPredicant && this.usagesForSelectedPredicant.responses.length
              ? this.usagesForSelectedPredicant.responses.map(function (responseQuery) {
                var query = responseQuery.query
                return h('.item.teal',
                  h('.header', h('a', {href: routes.authoringQuery.href({blockId: query.block, queryId: query.id})}, query.name)),
                  h('.menu',
                    responseQuery.responses.map(function (response) {
                      return h('.item.teal', response.text)
                    })
                  )
                )
              })
              : h('.item.teal', 'none')
          )
        )
      )
      : undefined
  )
}

module.exports = function (options) {
  return new PredicantsComponent(options)
}
