var http = require('../../http')

function nocache (req, next) {
  var now = Date.now()

  if (!req.options.params) {
    req.options.params = {}
  }
  req.options.params['no-cache'] = now

  return next()
}

module.exports = http.client([nocache])
