const hyperdom = require('hyperdom')
const httpism = require('httpism')
const cache = require('../common/cache')

class ContentPage {
  constructor (page) {
    this.page = page
  }

  onload () {
    return httpism.get('/pages/' + this.page + '.md').then(content => {
      this.content = content
    })
  }

  render () {
    if (this.content) {
      return hyperdom.rawHtml('.static-page', this.content)
    } else {
      return <div class="static-page"></div>
    }
  }
}

var contentCache = cache()

module.exports = function (page) {
  return contentCache.cacheBy(page, function () {
    return new ContentPage(page)
  })
}
