var hyperdom = require('hyperdom')
var h = hyperdom.html
var prototype = require('prote')
var semanticUi = require('./semanticUi')
var moment = require('moment')
var routes = require('./routes')

module.exports = prototype({
  constructor: function (options) {
    this.user = options.user
    this.documentApi = options.documentApi
    this.root = options.root
  },

  loadDocuments: function (limit=10,offset=0) {
    console.log('load documenst called');
    var self = this
    self.documentSearch = ''
    if (self.user) {
      return self.documentApi.allDocuments(limit,offset).then(function (documents) {
        //if(!self.documents)
          self.documents = [...documents];
          self.limit=limit;
          self.offset=offset;
          self.hasMoreRecords=documents.length==limit;
        // else
        //   self.documents = [...self.documents,...documents];
        // console.log('self.documents.length-->',self.documents.length)
        // offset=offset+limit;
        // if(documents.length==limit){
        //   self.loadDocuments(limit,offset)
        // }
      })
    }
  },

  createDocument: function () {
    return this.documentApi.create().then(function (doc) {
      routes.enote.push({documentId: doc.id})
    })
  },

  askToDeleteDocument: function (doc) {
    var self = this
    return new Promise(function (fulfil) {
      self.askToDeleteModal = true
      self.documentToDelete = doc
      self.tellDeleteDocument = function (goAheadWithDelete) {
        delete self.askToDeleteModal
        delete self.tellDeleteDocument
        fulfil(goAheadWithDelete)
      }
    })
  },

  loadDocument: function (id) {
    routes.enote.push({documentId: id})
  },

  deleteDocument: function (docToDelete) {
    var self = this

    return self.askToDeleteDocument(docToDelete).then(function (goAheadWithDelete) {
      if (goAheadWithDelete) {
        return self.documentApi.delete(docToDelete.id).then(function () {
          self.documents = self.documents.filter(function (doc) {
            return doc.id != docToDelete.id
          })
        })
      }
    })
  },

  filteredDocuments: function () {
    if (this.documentSearch) {
      var documentSearch = this.documentSearch.trim().toLowerCase()

      return this.documents.filter(function (document) {
        return document.name && document.name.trim().toLowerCase().indexOf(documentSearch) >= 0
          || (document.id && (String(document.id).toLowerCase() === documentSearch))
      })
    } else {
      return this.documents
    }
  },
  loadMore: function(){
    var self=this;    // const vdomFragment = hyperdom.append('tbody#enote-list',{
    // render: function () {
    //   var self = this;
    //   return h('tr.button.document', {onclick: function () {alert('hello')}},
    //   h('td.name', 'hello'),
    //   h('td','hello'),
    //   h('td','hello'),
    //   h('td', 'hello')
    // )
    // }});
    // console.log(vdomFragment)
    console.log("limit-->",self.limit)
    console.log("offset-->",self.offset)
    if(!self.limit)
      return;
    self.offset=self.limit+self.offset;
    return self.documentApi.allDocuments(self.limit,self.offset).then(function (documents) {
      //if(!self.documents)
        self.documents = [...self.documents,...documents];

        
        if(documents.length!==self.limit){
          self.isCompleted=true;
        }
        self.refreshComponent();
         
      // else
      //   self.documents = [...self.documents,...documents];
      // console.log('self.documents.length-->',self.documents.length)
      // offset=offset+limit;
      // if(documents.length==limit){
      //   self.loadDocuments(limit,offset)
      // }
    })
  },
  // 
  renderDocumentList: function () {
    var self = this;
    return h('div.your-documents',
      h('h1', 'Your documents'),
      h('table.table.ui.celled.documents',
        h('thead',
          h('tr',
            h('th', {colspan: 4},
              h('.ui.icon.input',
                h('input.document-search', {type: 'text', placeholder: 'Search...', binding: [self, 'documentSearch']}),
                h('i.search.icon')
              ),
              h('.ui.right.floated.button', {onclick: self.createDocument.bind(self)}, 'New Document')
            )
          ),
          h('tr',
            h('th', 'Name'),
            h('th', 'Created'),
            h('th', 'Modified'),
            hyperdom.rawHtml('th', '&nbsp;')
          )
        ),
        h('tbody#enote-list',
          self.filteredDocuments().map(function (doc) {
            return h('tr.button.document', {onclick: function () {return self.loadDocument(doc.id)}},
              h('td.name', documentName(doc)),
              h('td',moment(doc.created).format('LLL')),
              h('td',moment(doc.lastModified).format('LLL')),
              h('td', h('.ui.button.delete', {onclick: function (ev) { ev.stopPropagation(); return self.deleteDocument(doc) }}, 'delete'))
            )
          })
        )
      ),

      self.isCompleted?h('p',''):h('div.ui.segment.custom-segment', h('div.ui.active.inverted.dimmer', 
      h('div.ui.text.loader','loading..')
      )),

      self.askToDeleteModal
        ? semanticUi.modal(
          {
            onApprove: function () {
              self.tellDeleteDocument(true)
            },

            onDeny: function () {
              self.tellDeleteDocument(false)
            },

            onHide: function () {
              if (self.tellDeleteDocument) {
                self.tellDeleteDocument(false)
              }
            }
          },
          h('.ui.modal',
            h('.header', 'Are you sure you want to delete ' + documentName(self.documentToDelete) + '?'),
            h('.actions', h('.ui.button.ok', 'Ok'), h('.ui.button.cancel', 'Cancel'))
          )
        )
        : undefined
    )
  },

  renderGetStarted: function () {
    var self = this
    return h('div.no-documents',
      h('h1', 'Your documents'),
      h('p', 'You have not yet created any documents.'),
      h('p',
        h('a', {href: routes.contentPage.href({page: 'tutorial'})}, 'Read the tutorial'),
        ' or start a new document when you are ready:'
      ),
      h('.ui.button.new-document', {onclick: self.createDocument.bind(self)}, 'New Document')
    )
  },

  render: function () {
    var self = this;
    console.log("iscompleted-->",!self.isCompleted);
    if(!self.isCompleted)
      self.loadMore();
    return h('.documents',
      self.documents
        ? self.documents.length > 0
          ? self.renderDocumentList()
          : self.renderGetStarted()
        : h('.ui.active.inverted.dimmer.loading', h('.ui.text.loader', 'loading'))
    )
  },
})

function documentName (document) {
  return document.name || 'Untitled'
}
