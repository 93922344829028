var prototype = require('prote')
var userApi = require('./userApi')
var h = require('hyperdom').html
var routes = require('./routes')
var userComponent = require('./userComponent')

module.exports = prototype({
  constructor: function () {
    var self = this

    this.userApi = userApi()
    this.users = []

    this.userApi.changed.on(async () => {
      await this.searchUsers()
      self.refresh()
    })
  },

  onload: async function () {
    await this.searchUsers()
  },

  searchUsers: async function () {
    this.usersLoading = true
    if (!this.query) {
      this.users = await this.userApi.users({max: 20})
      delete this.usersLoading
    } else {
      this.users = await this.userApi.search(this.query)
      delete this.usersLoading
    }
  },

  loadUser: async function (userId) {
    this.userId = userId
    const user = await this.userApi.user(userId)
    this.user = userComponent({user: user.edit(), userApi: this.userApi})
  },

  loadNewUser: function () {
    this.userId = undefined
    this.user = userComponent({user: this.userApi.create(), userApi: this.userApi})
  },

  addUser: function () {
    routes.adminUser.push({userId: 'new'})
  },

  routes: function () {
    return [
      routes.admin({
        onload: () => this.userId = undefined,
        render: () => h('div')
      }),
      routes.adminCreateUser({
        onload: () => this.loadNewUser(),
        render: () => this.renderUser()
      }),
      routes.adminUser({
        onload: (location) => this.loadUser(location.params.userId),
        render: () => this.renderUser()
      }),
    ]
  },

  renderUser: function () {
    if (this.user) {
      return this.user
    } else {
      return h('div', 'loading')
    }
  },

  renderLayout: function (vdom) {
    return h('.admin',
      h('.search',
        h('.ui.button.create.green', {onclick: this.addUser.bind(this)}, 'add user'),
        h('.ui.divider'),
        h('.ui.icon.input', {class: {loading: this.usersLoading}},
          h('input', {type: 'text', placeholder: 'search users', binding: [this, 'query', () => this.searchUsers()]}),
          h('i.search.icon')
        ),
        h('.ui.vertical.menu.results',
          this.users.map((user) => {
            return h('a', {href: routes.adminUser.href({userId: user.id}), class: {item: true, teal: true, active: user.id === this.userId}},
              h('h5', (user.firstName || '') + ' ' + (user.familyName || '')),
              user.email
            )
          })
        )
      ),
      vdom
    )
  }
})
