var http = require('./http')
var prototype = require('prote')

var documentPrototype = require('./document')

module.exports = prototype({
  currentDocument: function () {
    return http.get('/api/user/documents/current', {showErrors: false}).then(documentPrototype, function () {
    })
  },

  document: function (id, options) {
    if (id instanceof Object) {
      return documentPrototype(id)
    } else {
      return http.get('/api/user/documents/' + id, options).then(documentPrototype)
    }
  },

  create: function () {
    return http.post('/api/user/documents', documentPrototype()).then(documentPrototype)
  },

  delete: function (id) {
    return http.delete('/api/user/documents/' + id)
  },

  allDocuments: function (limit,offset) {
    console.log('allDocuments-->',limit,offset);
    return http.get(`/api/user/documents?limit=${limit}&offset=${offset}`).then(function (documents) {
      return documents.map(documentPrototype)
    })
  }
})

