var hyperdom = require('hyperdom')
var h = hyperdom.html
var prototype = require('prote')
var semanticUi = require('./semanticUi')
var _ = require('underscore')
var queryComponent = require('./queryComponent')
var debugComponent = require('./debugComponent')
var documentComponent = require('./documentComponent')
var createHistory = require('./history')
var routes = require('./routes')
var zeroClipboard = require('./zeroClipboard')
var vdomToHtml = require('vdom-to-html')
var wait = require('./wait')
var CodedOutputView = require('./codedOutputView')

function dirtyBinding (model, name, component) {
  return {
    get: function () {
      return model[name]
    },
    set: function (value) {
      model[name] = value
      model.dirty = true
      return component
    }
  }
}

module.exports = prototype({
  constructor: function (options) {
    var self = this

    this.document = options.document

    this.debug = debugComponent({
      currentQuery: function () {
        return self.history.query
      },
      lexemeApi: options.lexemeApi,
      selectedResponse: function () {
        return self.query.selectedResponse()
      },
      variables: function () {
        return self.history.variables()
      }
    })

    function setQuery (query) {
      self.query.setQuery(query)
    }

    function refresh () {
      if (self.refresh) {
        self.refresh()
      }
    }

    this.history = createHistory({
      document: options.document,
      queryGraph: options.queryGraph,
      setQuery: setQuery,
      refresh: refresh,
      lexemeApi: options.lexemeApi
    })

    this.codedOutput = new CodedOutputView({history: this.history})

    this.documentStyle = {
      style: 'style1'
    }

    this.query = queryComponent({
      user: options.user,
      history: this.history,
      debug: this.debug,
      documentStyle: this.documentStyle
    })

    this.documentComponent = documentComponent({
      history: this.history,
      setQuery: setQuery
    })
  },

  currentQuery: function () {
    return this.query.query
  },

  throttledSaveDocument: _.throttle(function () {
    var self = this

    delete this.document.dirty
    this.document.update().then(function () {
      if (self.refresh) {
        self.refresh()
      }
    })
  }, 500, {leading: false}),

  render: function () {
    var self = this

    return h('div.enote',
      self.renderEnoteName(),
      self.query,
      h('.document-tabs',
        semanticUi.tabs(
          '.ui.top.attached.tabular.menu',
          {
            binding: [self.documentStyle, 'style'],
            tabs: [
              {
                key: 'style1',
                tab: h('a.item.style-normal', 'Normal'),
                content: function (key) {
                  return h('.ui.bottom.attached.tab.segment', self.documentComponent.render(key))
                }
              },
              {
                key: 'style2',
                tab: h('a.item.style-abbreviated', 'Abbreviated'),
                content: function (key) {
                  return h('.ui.bottom.attached.tab.segment', self.documentComponent.render(key))
                }
              },
              {
                key: 'coded',
                tab: h('a.item.style-coded', 'Coded'),
                content: function () {
                  return h('.ui.bottom.attached.tab.segment', self.codedOutput)
                }
              },
              {
                key: 'debug',
                tab: h('a.item.debug', 'Debug'),
                content: function () {
                  return h('.ui.bottom.attached.tab.segment', self.debug)
                }
              }
            ]
          }
        ),
        h('.actions', [
          zeroClipboard(
            {
              oncopy: function () {
                var self = this
                self.copied = true
                return wait(1000).then(function () {
                  self.copied = false
                })
              },
              onerror: function () {
                this.noFlash = true

                if (this.clicked) {
                  self.selectDocument()
                }
              }
            },
            {
              'text/plain': function () {
                var html = vdomToHtml(self.documentComponent.render(self.documentStyle.style))
                var element = document.createElement('div')
                element.innerHTML = html
                return element.innerText
              },
              'text/html': function () {
                return vdomToHtml(self.documentComponent.render(self.documentStyle.style))
              }
            },
            function () {
              var copyButton = this
              if (copyButton.noFlash) {
                return h(
                  '.ui.button',
                  {
                    onclick: function () {
                      copyButton.clicked = true
                      self.selectDocument()
                    }
                  },
                  'Select All'
                )
              } else {
                return h('.ui.button', {class: {copied: this.copied}}, this.copied? 'Copied!': 'Copy')
              }
            }
          ),
          h('.ui.button', {
            onclick: function () {self.print()}},
          'Print'),
          h('.ui.button', 'Discard')
        ])
      )
    )
  },

  selectDocument: function () {
    var doc = document.querySelector('.document-outer')
    var range = document.createRange()
    range.selectNode(doc)
    var selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  },

  print: function () {
    location.href = routes.printEnote.url({documentId: this.document.id, style: this.documentStyle.style})
  },

  renderPrint: function () {
    return this.documentComponent.render(this.documentStyle.style)
  },

  renderEnoteName: function () {
    var self = this

    return hyperdom.viewComponent({
      renderKey: 'enote',
      enote: true,
      render: function (component) {
        if (self.document.dirty) {
          self.throttledSaveDocument()
        }

        return h('.field.inline.document-name',
          h('label', 'Document Name'),
          h('.ui.icon.right.labeled.input', {class: {loading: self.document.dirty}},
            h('input.enote-name', {type: 'text', placeholder: 'Untitled', binding: dirtyBinding(self.document, 'name', component)}),
            h('.ui.green.label', self.document.dirty? 'saving...': 'saved')
          )
        )
      }
    })
  }
})
