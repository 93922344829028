var mapBinding = require('hyperdom/mapBinding')

module.exports = function (entity) {
  return function () {
    var binding = mapBinding.apply(undefined, arguments)
    var originalSet = binding.set
    binding.set = function (value) {
      entity._dirty = true
      originalSet(value)
    }
    return binding
  }
}
