var hyperdom = require('hyperdom')
var h = hyperdom.html
var routes = require('./routes')
var http = require('./http')
var _ = require('underscore')
var wait = require('./wait')
var join = require('./join')

module.exports = function (model, contents) {
  listenToHttpErrors(model)

  return h('div#wrapper',
    h('div.main',
      h('div.top-menu',
        h('div.content',
          h('div.logo',
            h('img',{src: '/source/logo.png'})
          ),
          authStatus(model.user),
          topMenuTabs(model),
          topMenuButtons(model)
        )
      ),
      h('div.shadow'),
      model.flash
        ? renderFlash(model)
        : undefined,
      h('div.content', contents)
    ),
    footer(model)
  )
}

function renderFlash (model) {
  var flash = model.flash

  function close () {
    return h('a.close', {onclick: function () { delete model.flash }})
  }

  if (flash instanceof Array) {
    return flash.map(function (flashMessage) {
      return h('div.ignored.ui.message', {class: flashMessage.type}, hyperdom.rawHtml('span', flashMessage.message), close())
    })
  } else if (flash instanceof Object) {
    return h('div.ignored.ui.message.warning',
      h('.message', flash.message),
      h('.detail', flash.detail),
      close()
    )
  } else if (flash) {
    return h('div.ignored.ui.message.warning', flash, close())
  }
}

function topMenuTabs (model) {
  function routeTab (page, title) {
    return h('a', {href: model.blogUrl + page}, title)
  }

  return h('div.tabs',
    join([
      routeTab('about', 'About'),
      routeTab('user-s-manual-1', 'User\'s Manual'),
      routeTab('author-s-manual', 'Author\'s Manual'),
    ], '|')
  )
}

function topMenuButtons (model) {
  var query = model.query()

  function routeTab (route, params, title, active) {
    return h('a', {href: route.href(params), class: {ui: true, button: true, active: active !== undefined ? active : route.isActive(params)}}, title)
  }

  function authoringTab () {
    if (model.user.author) {
      if (query && query.query) {
        return routeTab(
          routes.authoringQuery,
          {blockId: query.query.block, queryId: query.query.id},
          'Authoring'
        )
      } else {
        return routeTab(
          routes.authoring.base,
          undefined,
          'Authoring'
        )
      }
    }
  }

  function adminTab () {
    if (model.user.admin) {
      return routeTab(routes.admin, undefined, 'Admin', routes.admin.isActive() || routes.adminUser.isActive())
    }
  }

  function enoteTab () {
    var currentDocument = model.currentDocument()

    if (currentDocument) {
      if (routes.authoring.base.isActive()) {
        var href = routes.enote.href({documentId: currentDocument.id})
        return h('a.ui.button.enote', {href: href, title: currentDocument.name}, 'eNOTE')
      } else {
        href = routes.enote.href({documentId: currentDocument.id})
        var isActive = routes.enote.isActive({documentId: currentDocument.id})
        return h('a', {href: href, class: {ui: true, button: true, active: isActive, enote: true}}, 'eNOTE')
      }
    }
  }

  return h('div.buttons',
    model.user
      ? [
        routeTab(routes.root, undefined, 'Home'),
        enoteTab(),
        authoringTab(),
        adminTab()
      ]
      : undefined
  )
}

function authStatus (user) {
  return h('div.user', {class: {'logged-out': !user, 'logged-in': user}},
    user
      ? [
        h('span', user.email),
        h('form.logout', {method: 'POST', action: '/logout'},
          h('input', {type: 'submit', value: 'Logout'})
        )
      ]
      : undefined
  )
}

function footer (model) {
  return h('footer',
    'LEXeNOTES is  the registered trademark of Lexeme Technologies LLC.',
    h('br'),
    'Copyright 2017 Lexeme Technologies LLC. All rights protected. US Patent #8,706,680.',
    h('span.release-time', 'Released: ' + new Date(model.releaseTime))
  )
}

var listenToHttpErrors = _.once(function (model) {
  http.onError(h.refreshify(function (error) {
    if (!error.aborted) {
      var errorMessage = error.statusText + (
        error.responseJSON && error.responseJSON.message
          ? ': ' + error.responseJSON.message
          : error.responseText
            ? ': ' + error.responseText
            : ''
      )

      model.flash = {message: 'Network Error', detail: errorMessage}
      return wait(3000).then(function () {
        delete model.flash
      })
    }
  }))

  http.onInactivity = function () {
    http.post('/logout').then(function () {
      window.location = routes.login.url({inactive: true})
    })
  }
})
